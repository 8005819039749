import cn from "classnames";

import { Header } from "components/header";
import { TimerBox } from "components/timer";
import ContractAddress from "components/contractAddress/contractAddress";

import ArrowRightIcon from "assets/svgs/arrow-right.svg";
import AmericaFlag from "assets/america-flag.png";
import WalletIcon from "assets/svgs/wallet.svg";
import ArrowLineDashedIcon from "assets/svgs/arrow-line-dashed.svg";
import DownloadIcon from "assets/svgs/download.svg";
import BookmarkIcon from "assets/svgs/bookmark.svg";

import { formatCompactNumber } from "utils";
import SocialLinks from "components/socialLinks/socialLinks";
import { TEAMS, WALLETS } from "utils/constants";
import Footer from "components/footer/footer";
import "./index.scss";
import { ConnectWallet } from "components/connectWallet";
import { useEffect, useState } from "react";
import { FullScreenLoader } from "components/loading/FullScreenLoading";

export function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const infos = [
    { amount: 109100000, description: ["Circulating", "Supply"] },
    { amount: 333000000, description: ["Total", "Supply"] },
    { amount: 333000000, description: ["Max", "Supply"] }
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="flex flex-col bg-[#1B2B4B]">
      {isLoading && <FullScreenLoader />}
      <div className={cn("object-cover home-container")}>
        <span className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/0"></span>
        <div className="container mx-auto sm:my-12 my-4 px-4 sm:px-0 relative">
          <Header />

          <div className="flex w-full sm:mt-16 mt-4 sm:flex-row flex-col-reverse">
            <div className="sm:max-w-[50%]">
              <h1 className="font-bold text-3xl sm:text-5xl md:text-[81px] font-oxanium leading-[3.5rem] text-center md:text-left md:leading-[6.25rem]">
                <span className="text-gradient">PDJT</span> Movement on
                <span> The Blockchain</span>
              </h1>
              <p className="font-[ClashDisplay] text-base text-center md:text-left md:text-xl font-medium leading-8 md:leading-[38px]">
                {`This isn't a meme token. It's the first blockchain-based version of
            the presidency, on Solana. Trump's multifaceted endeavors
            encompassed tackling terrorism, promoting global tranquility, and
            navigating the complexities of cryptocurrency, leaving an indelible
            mark on his presidency.`}
              </p>
              <ContractAddress />

              <div className="hidden md:grid grid-cols-2 mt-6 gap-6">
                <a
                  href="https://raydium.io/swap/?outputMint=E2o6gnnPa5FHW5kwLj7eNwPe77RtpBQn3nbX25fJ4Fyi&inputMint=sol"
                  rel="noreferrer"
                  target="_blank"
                  className="btn gap-2 btn-blue drop-shadow-blue font-black font-[ClashDisplay] text-xl py-6"
                >
                  <span>Trade $PDJ</span>
                  <img src={ArrowRightIcon} alt="" />
                </a>
                <a
                  href="https://t.me/Vote_Pdjt_bot"
                  target="_blank"
                  className="btn btn-red drop-shadow-red font-black font-clash-display text-xl py-6"
                  rel="noreferrer"
                >
                  Play Now
                </a>
              </div>
            </div>

            <div className="flex sm:flex-1 sm:justify-end justify-center items-end">
              <TimerBox />
            </div>
          </div>

          <div className="flex mt-6 justify-between sm:flex-row flex-col-reverse gap-6">
            <div className="flex sm:gap-16 gap-6 sm:flex-row flex-col">
              {infos.map((info, key) => (
                <div
                  key={key}
                  className="text-white grid gap-1 shadow-lg bg-gradient-to-br border border-l-0 border-white/10 from-white/5 to-white/0 rounded-3xl p-6 lg:bg-none lg:border-0 text-center lg:text-left min-h-44 content-center lg:min-h-0 lg:p-0"
                >
                  <strong className="font-semibold font-clash-display text-3xl">
                    {`${formatCompactNumber(info.amount, {})} PDJT`}
                  </strong>
                  <span className="font-inter text-sm font-medium">
                    Circulating <br className="hidden lg:block" /> Supply
                  </span>
                </div>
              ))}
            </div>

            <SocialLinks className="flex gap-4 justify-center" limit={4}/>
          </div>
        </div>
      </div>

      <div className="sub-home-container relative pt-6 sm:pt-24 ">
        <div className="flex flex-col sm:gap-32 gap-6">
          <div className="relative container mx-auto flex justify-center items-center gap-8 sm:gap-x-24 gap-y-10 flex-wrap px-4 sm:px-0">
            {WALLETS.map((item, index) => (
              <a
                key={index}
                href={item.link}
                className="basis-44 sm:basis-56 md:basis-72 group"
              >
                <img
                  width={294}
                  height={60}
                  src={item.image}
                  alt=""
                  className="w-full group-last:h-20 object-contain"
                />
              </a>
            ))}
          </div>

          <div className="grid relative container mx-auto">
            {/* <div className="absolute inset-0 bg-[radial-gradient(circle,_rgba(1,148,254,1)_0%,_rgba(255,255,255,0)_100%)]"></div> */}
            <div className="sm:grid flex flex-col px-4 sm:px-0 relative">
              <div className="grid justify-center text-center">
                <h2 className="text-3xl md:text-7xl font-semibold mx-auto text-center mb-4">
                  Roadmap : The Journey of PDJT
                </h2>
                <p className="font-medium text-lg md:text-3xl">
                  Our roadmap outlines the exciting future of PDJT phases
                  include:
                </p>
              </div>
              <div className="grid w-full gap-y-24 lg:grid-cols-3 mt-28">
                <div className="flex justify-center lg:justify-start">
                  <div className="grid">
                    <div
                      className="border-2 border-r-0 bg-clip-padding px-4 py-6 mx-auto font-black text-6xl font-outfit"
                      style={{
                        borderImage:
                          "linear-gradient(90deg, #FB0404, #FB0404, rgba(255,255,255,0.3), rgba(255,255,255,0.0)) 1"
                      }}
                    >
                      Q1
                    </div>

                    <span className="h-28 w-px bg-gradient-to-b from-red/0 via-red to-red/0 mx-auto flex my-4"></span>

                    <div
                      className="p-2 sm:p-4 bg-clip-padding mx-auto border-2 border-b-0 sm:w-[344px]"
                      style={{
                        borderImage:
                          "linear-gradient(180deg, #FB0404, #FB0404, rgba(255,255,255,0.3), rgba(255,255,255,0.0)) 1"
                      }}
                    >
                      <h3 className="text-red-50 font-bold text-xl sm:text-2xl text-center">
                        Launch and Building
                      </h3>
                      <ul className="pl-4 grid gap-2 sm:gap-4 mt-4 font-bold font-jura text-sm sm:text-base lg:text-xl">
                        <li className="relative before:w-1 before:h-1 before:rounded-full before:bg-white before:absolute before:-left-4 before:top-3 text-start">
                          Lunching liquidity pool at raydium swap{" "}
                          <span className="text-red-50">☑️</span>
                        </li>
                        <li className="relative before:w-1 before:h-1 before:rounded-full before:bg-white before:absolute before:-left-4 before:top-3 text-start">
                          Website Launch
                        </li>
                        <li className="relative before:w-1 before:h-1 before:rounded-full before:bg-white before:absolute before:-left-4 before:top-3 text-start">
                          Whitepaper Release
                        </li>
                        <li className="relative before:w-1 before:h-1 before:rounded-full before:bg-white before:absolute before:-left-4 before:top-3 text-start">
                          CMC, CG & Dextools Listing
                        </li>
                        <li className="relative before:w-1 before:h-1 before:rounded-full before:bg-white before:absolute before:-left-4 before:top-3 text-start">
                          Preparing Airdrop bot
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="grid">
                  <div
                    className="bg-clip-padding mx-auto border-2 border-t-0 sm:w-[344px]"
                    style={{
                      borderImage:
                        "linear-gradient(0deg, #FB0404, #FB0404, rgba(255,255,255,0.3), rgba(255,255,255,0.0)) 1"
                    }}
                  >
                    <div className="board-wrap w-full grid text-center content-center items-center justify-center aspect-square">
                      <img
                        src={AmericaFlag}
                        className="w-56 h-56 object-cover"
                        width={300}
                        height={300}
                        alt="america-flag"
                      />
                      <p className="text-gradient-reverse font-bold text-3xl">
                        Coming <br /> Soon
                      </p>
                    </div>
                  </div>
                  <span className="h-28  -order-1 lg:order-1 w-px bg-gradient-to-b from-red/0 via-red to-red/0 mx-auto flex my-4"></span>
                  <div
                    className="border-2 -order-2 lg:order-2 border-t-0 bg-clip-padding px-4 py-6 mx-auto font-black text-6xl font-outfit"
                    style={{
                      borderImage:
                        "linear-gradient(0deg, #FB0404, #FB0404, rgba(255,255,255,0.3), rgba(255,255,255,0.0)) 1"
                    }}
                  >
                    Q2
                  </div>
                </div>
                <div className="flex justify-center lg:justify-end">
                  <div className="grid shrink-0">
                    <div
                      className="border-2 border-l-0 bg-clip-padding px-4 py-6 mx-auto font-black text-6xl font-outfit"
                      style={{
                        borderImage:
                          "linear-gradient(-90deg, #FB0404, #FB0404, rgba(255,255,255,0.3), rgba(255,255,255,0.0)) 1"
                      }}
                    >
                      Q3
                    </div>

                    <span className="h-28 w-px bg-gradient-to-b from-red/0 via-red to-red/0 mx-auto flex my-4"></span>

                    <div
                      className="bg-clip-padding mx-auto border-2 border-b-0 sm:w-[344px]"
                      style={{
                        borderImage:
                          "linear-gradient(180deg, #FB0404, #FB0404, rgba(255,255,255,0.3), rgba(255,255,255,0.0)) 1"
                      }}
                    >
                      <div className="board-wrap w-full grid text-center content-center items-center justify-center aspect-square">
                        <img
                          src={AmericaFlag}
                          className="w-56 h-56 object-cover"
                          width={300}
                          height={300}
                          alt="america-flag"
                        />
                        <p className="text-gradient-reverse font-bold text-3xl">
                          Coming <br /> Soon
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid relative container mx-auto px-4 sm:px-0">
            <div className="grid w-full shadow-lg bg-gradient-to-br border border-l-0 border-white/10 from-white/5 to-white/0 py-16 px-4 md:px-8 rounded-3xl">
              <h2 className="font-semibold text-2xl md:text-5xl text-center mb-16">
                How To Buy PDJT
              </h2>

              <div className="grid lg:grid-cols-3 gap-x-4 gap-y-8 items-start">
                <div className="grid gap-8 justify-center text-center font-poppins relative">
                  <img src={WalletIcon} className="mx-auto" alt="" />
                  <div className="absolute left-2/3 top-7 hidden lg:block lg:w-52 xl:w-72 2xl:w-[20rem]">
                    <img src={ArrowLineDashedIcon} className="w-full" alt="" />
                  </div>
                  <h3 className="font-semibold text-xl xl:text-3xl">
                    Set up your wallet
                  </h3>
                  <p className="font-normal text-sm xl:text-lg w-full break-words text-center">
                    Once you’ve set up your wallet of choice, connect it to
                    phantom by clicking the wallet icon in the top right corner.
                    Learn about the wallets we support.
                  </p>
                </div>
                <div className="grid gap-8 justify-center text-center font-poppins relative">
                  <img src={DownloadIcon} className="mx-auto" alt="" />
                  <div className="absolute left-2/3 top-7 hidden lg:block lg:w-52 xl:w-72 2xl:w-[20rem]">
                    <img src={ArrowLineDashedIcon} className="w-full" alt="" />
                  </div>
                  <h3 className="font-semibold text-xl xl:text-3xl">
                     Purchase Some Solana
                  </h3>
                  <p className="font-normal text-sm xl:text-lg w-full break-words text-center">
                    From inside the phantom app you can purchase solana using
                    PayPal by clicking on the buy button.
                  </p>
                </div>
                <div className="grid gap-8 justify-center text-center font-poppins">
                  <img src={BookmarkIcon} className="mx-auto" alt="" />
                  <h3 className="font-semibold text-xl xl:text-3xl">
                    Swap Your SOL for PDJT
                  </h3>
                  <p className="font-normal text-sm xl:text-lg w-full break-words text-center flex">
                    Connect your wallet to Raydium, input our contract
                    E2o6gnnPa5FHW5kwLj7eNwPe77Rt
                    <br />
                    pBQn3nbX25fJ4Fyi, set your slippage to 1.5%, and click the
                    swap button!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grid relative container mx-auto px-4 sm:px-0">
            <h2 className="font-semibold text-5xl md:text-6xl text-center mb-16">
              Our Team
            </h2>

            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
              {TEAMS.map((item, index) => (
                <div
                  key={index}
                  className="grid gap-4 p-4 bg-white/10 backdrop-blur-sm relative"
                >
                  <img
                    className="w-full"
                    src={item.image}
                    width={331}
                    height={331}
                    alt={item.name}
                  />
                  <div className="flex items-center justify-between">
                    <span className="text-xl font-bakbak">{item.name}</span>
                    <div className="flex items-center gap-2">
                      {Object.keys(item.links).map((social) => (
                        <a
                          key={social}
                          target="_blank"
                          href={item.links[social as keyof typeof item.links]}
                          rel="noreferrer"
                        >
                          <img
                            src={`/socials/${social}.png`}
                            width={30}
                            height={30}
                            alt={social}
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="grid relative container mx-auto px-4 sm:px-0">
            <h2 className="font-semibold text-4xl md:text-6xl text-center mb-8 sm:mb-16">
              Ready for Airdrop?
            </h2>

            <div className="flex items-center justify-center">
              <ConnectWallet
                className="btn gap-2 btn-sm ml-6 h-20 rounded-3xl px-8 text-2xl font-bold font-clash-display btn-gradient-2 button-shadow-1"
                extra={<img src={ArrowRightIcon} alt="" />}
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
      
    </div>
  );
}
