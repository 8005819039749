'use client';
import { useTimer } from "react-timer-hook";
export const TimerBox = () => {
  const expiryTimestamp = new Date("2025-01-20");
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called")
  });

  return (
    <div className="flex items-center sm:flex-col gap-4">
      <div className="bg-blur sm:grow xl:grow-0 relative border border-white/30 p-2 sm:px-8 sm:gap-6 font-clash-display sm:p-4 rounded-2xl -skew-x-12 flex sm:flex-row flex-col items-center justify-end">
        <strong className="font-semibold text-sm md:text-xl">Auction</strong>
        <span className="font-medium text-sm md:text-base">End In</span>
      </div>
      <div className="bg-blur sm:grow xl:grow-0 relative border border-white/30 p-2 sm:px-8 gap-2 sm:gap-6 font-clash-display sm:p-4 rounded-2xl -skew-x-12 flex items-center">
        <div className="grid text-center">
          <strong className="font-semibold text-sm sm:text-xl md:text-3xl">
            {days * 24 + hours < 10
              ? "0" + days * 24 + hours
              : days * 24 + hours}
          </strong>
          <span className="font-medium text-sm md:text-base">Hours</span>
        </div>
        <span className="text-sm sm:text-2xl font-semibold">:</span>
        <div className="grid text-center">
          <strong className="font-semibold text-sm sm:text-xl md:text-3xl">
            {minutes < 10 ? "0" + minutes : minutes}
          </strong>
          <span className="font-medium text-sm md:text-base">Minutes</span>
        </div>
        <span className="text-2xl font-semibold">:</span>
        <div className="grid text-center">
          <strong className="font-semibold text-sm sm:text-xl md:text-3xl">
            {seconds < 10 ? "0" + seconds : seconds}
          </strong>
          <span className="font-medium text-sm md:text-base">Seconds</span>
        </div>
      </div>
    </div>
  );
};
