export const BaseLayout = (props: any) => {
  const { children } = props;

  return (
    <div className="relative">
      <div className={`relative w-screen max-h-screen hide-scrollbars`}>
        <main className="relative mx-auto main h-full w-full text-white ">
          {children}
        </main>
      </div>
    </div>
  );
};
