import cn from "classnames";

import "./index.scss";

export function ComingSoon() {
  return (
    <>
      <div className="flex flex-col bg-[#1B2B4B] h-screen">
        <div className={cn("object-cover home-container")}>
            <h1 className="text-[64px]">Coming Soon</h1>
        </div>
      </div>
    </>
  );
}
