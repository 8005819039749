import { ConnectButton } from "@rainbow-me/rainbowkit";
import cn from "classnames";
import Menu from "./menu";

import Trump from "assets/header/trump.png";
import CMS1 from "assets/header/cms/cms1.png";
import CMS2 from "assets/header/cms/cms2.png";
import CMS3 from "assets/header/cms/cms3.png";
import { ConnectWallet } from "components/connectWallet";

export const Header = () => {
  return (
    <header className="relative z-50">
      <div
        className={cn(
          "flex items-center justify-between",
          "bg-gradient-to-br from-white/30 to-white/10 shadow-xl backdrop-blur-lg rounded-2xl",
          "sm:py-3 sm:px-4 py-2 px-2"
        )}
      >
        <Menu />

        <div className="sm:block absolute hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div
            className={cn(
              "w-[120px] aspect-square bg- rounded-full flex items-center justify-center",
              "bg-gradient-to-br from-white/30 to-white/10 shadow-xl backdrop-blur-lg rounded-2xl"
            )}
          >
            <img
              src={Trump}
              className="w-[134px] rounded-full ml-2"
              width={350}
              height={350}
              alt="logo"
            />
          </div>
        </div>

        <div className="flex items-center justify-end h-full gap-3">
          <div className="flex items-center gap-3">
            <a
              href="https://solscan.io/token/E2o6gnnPa5FHW5kwLj7eNwPe77RtpBQn3nbX25fJ4Fyi#holders"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={CMS1}
                className="sm:w-12 sm:h-12 w-6 h-6 rounded-lg"
                width={49}
                height={49}
                alt="logo"
              />
            </a>

            <a
              href="https://coinmarketcap.com/currencies/president-donald-j-trump/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={CMS2}
                className="sm:w-12 sm:h-12 w-6 h-6 rounded-lg"
                width={49}
                height={49}
                alt="logo"
              />
            </a>

            <a
              href="https://solscan.io/token/E2o6gnnPa5FHW5kwLj7eNwPe77RtpBQn3nbX25fJ4Fyi#holders"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={CMS3}
                className="sm:w-12 sm:h-12 w-6 h-6 rounded-lg"
                width={49}
                height={49}
                alt="logo"
              />
            </a>
          </div>
          <ConnectWallet />
        </div>
      </div>
    </header>
  );
};
