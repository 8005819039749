import "@rainbow-me/rainbowkit/styles.css";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { mainnet, polygon, optimism, arbitrum, base } from "wagmi/chains";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import "./App.css";
import { BaseLayout } from "./layouts/base.layout";
import { BrowserRouter } from "react-router-dom";
import { BaseRoute } from "routes/base.route";
import { WagmiProjectId, WagmiProjectName } from "utils/constants";

const queryClient = new QueryClient();

function App() {
  console.log(WagmiProjectId, WagmiProjectName)
  const config = getDefaultConfig({
    appName: WagmiProjectName,
    projectId: WagmiProjectId,
    chains: [mainnet, polygon, optimism, arbitrum, base],
    ssr: true // If your dApp uses server side rendering (SSR)
  });

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <div className="App font-[Oxanium]">
            <BaseLayout>
              <BrowserRouter>
                <BaseRoute />
              </BrowserRouter>
            </BaseLayout>
          </div>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
