import instagram from "assets/socials/instagram.png";
import twitter from "assets/socials/x.png";
import youtube from "assets/socials/youtube.png";
import telegram from "assets/socials/telegram.png";
import medium from "assets/socials/medium.png";
import github from "assets/socials/github.png";
import discord from "assets/socials/discord.png";


const socials = [
  {
    image: instagram,
    link: "https://www.instagram.com/pdj_trump"
  },
  { image: twitter, link: "https://twitter.com/pd_jtrump" },
  { image: youtube, link: "" },
  { image: telegram, link: "https://t.me/Pdjtrump" },
  { image: medium, link: "https://medium.com/@PDJTRUMP" },
  { image: github, link: "https://github.com/PDJTrump" },
  { image: discord, link: "https://discord.gg/XaevPy6z" }
];

export default function SocialLinks({
  limit,
  size = 43,
  className = ""
}: {
  limit?: number;
  size?: number;
  className?: string;
}) {
  const list = limit ? socials.slice(0, limit) : socials;

  return (
    <div className={className}>
      {list.map((item, index) => (
        <a key={index} href={item.link} target="_blank" rel="noreferrer">
          <img
            className="aspect-square rounded-full"
            style={{ width: size, height: size }}
            src={item.image}
            width={size * 2}
            height={size * 2}
            alt="social"
          />
        </a>
      ))}
    </div>
  );
}
