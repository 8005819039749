import "./index.scss";
import loading from "assets/loading.png";

export function FullScreenLoader() {
  return (
    <div className="loader pointer-events-none">
      <div className="spinner">
        <img src={loading} className="text-[80px] sm:w-[120px] h-auto" alt="" />
      </div>
      <div className="text-[32px] sm:text-[46px] font-semibold text-white">Loading</div>

      <div className="absolute bottom-[10px] w-full flex flex-col items-center justify-center">
        <span className="text-[16px] sm:text-[26px] font-medium">Save the date for PDJTrump listing</span>
        <span className="text-[42px] sm:text-[50px] text-red font-semibold">November 5th!</span>
      </div>
    </div>
  );
}
