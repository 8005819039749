import cn from "classnames";
import { RxDashboard } from "react-icons/rx";

import avatar from "assets/Avatar.png";
import WhitepaperIcon from "assets/svgs/Sidebar/whitepaper.svg";
import USAFlagIcon from "assets/svgs/Sidebar/usa-flag.svg";
import BotIcon from "assets/svgs/Sidebar/bot.svg";
import ContactUsIcon from "assets/svgs/Sidebar/contact-us.svg";
import SocialMediaManagementIcon from "assets/svgs/Sidebar/social-media-management.svg";
import CalendarEditIcon from "assets/svgs/Sidebar/Iconsans/Linear/Calendar-Edit.svg";
import HomeIcon from "assets/svgs/Sidebar/Iconsans/Linear/Home-1.svg";
import MapIcon from "assets/svgs/Sidebar/Iconsans/Linear/Map.svg";
import TransferVerticalIcon from "assets/svgs/Sidebar/Iconsans/Linear/Transfer-Vertical.svg";
import UsersIcon from "assets/svgs/Sidebar/Iconsans/Linear/Users-4.svg";
import { useEffect, useRef, useState } from "react";

import "./index.scss";

const list = [
  [
    { title: "Home", link: "/coming-soon", icon: <img src={HomeIcon} className="w-4 h-4" alt="home"/> },
    { title: "Profile", link: "/coming-soon", icon: <img src={UsersIcon} className="w-4 h-4" alt="user" /> },
    { title: "Swap", link: "/coming-soon", icon: <img src={TransferVerticalIcon} className="w-4 h-4" alt="transfer"/> },
    { title: "Bot", link: "https://t.me/Vote_Pdjt_bot", icon: <img src={BotIcon} className="w-4 h-4" alt="bot" /> },
    { title: "Roadmap", link: "/coming-soon", icon: <img src={MapIcon} className="w-4 h-4" alt="map" /> },
    {
      title: "Whitepaper",
      link: "https://pdjtrump.gitbook.io/pdjtrump-whitepaper/",
      icon: <img src={WhitepaperIcon} className="w-4 h-4" alt="whitepaper"/>
    }
  ],
  [
    { title: "Province policy", link: "/coming-soon", icon: <img src={CalendarEditIcon} className="w-4 h-4" alt="policy"/> },
    { title: "Country USA", link: "/coming-soon", icon: <img src={USAFlagIcon} className="w-4 h-4" alt="country" /> }
  ],
  [
    { title: "Social media", link: "/coming-soon", icon: <img src={SocialMediaManagementIcon} className="w-4 h-4" alt="social media" /> },
    { title: "Contact", link: "/coming-soon", icon: <img src={ContactUsIcon} className="w-4 h-4" alt="contact" /> }
  ]
];

export default function Menu() {
  const [open, setOpen] = useState(false);
  const root = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (
      !root.current ||
      root.current?.contains(e.target as HTMLElement) ||
      root.current === e.target
    )
      return;

    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    return () => window.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <div ref={root} className={`relative flex items-center group ${open ? "open" : ""}`}>
      <button className="text-white" onClick={() => setOpen(!open)}>
        <RxDashboard className="sm:!w-10 sm:!h-10 w-6 h-6" />
      </button>
      <div
        className={cn(
          "grid content-start rounded-2xl",
          "menu-bg bg-cover bg-no-repeat",
        //   "bg-gradient-to-r from-[#0F0909] via-[#450707] via-[22%] to-[#FF0000] opacity-50",
          "transition-all absolute top-full -left-4",
          "w-60 opacity-0 invisible group-[.open]:visible group-[.open]:opacity-100"
        )}
      >
        <div className="p-6 grid gap-6 max-h-full">
          <div className="flex items-center">
            <div className="rounded-full bg-red-300">
              <img
                src={avatar}
                className="w-12 aspect-square rounded-full"
                width={80}
                height={80}
                alt="avatar"
              />
            </div>
            <p className="font-inter font-semibold ml-4">John Osborne</p>
          </div>
          <span className="w-full h-0.5 bg-gradient-to-r from-white/0 via-white/50 to-white/0"></span>

          {list.map((links) => (
            <>
              <div className="grid grid-cols-2 gap-6">
                {links.map((item, index) => (
                  <a
                    key={index}
                    className="grid leading-1 font-inter text-white/60 gap-1 content-center items-center justify-center w-[81px] h-[72px] bg-gradient-to-b from-white/10 to-white/5 text-xs text-center rounded-xl text-wrap"
                    href={item.link}
                  >
                    <span className="mx-auto">{item.icon}</span>
                    <span>{item.title}</span>
                  </a>
                ))}
              </div>
              <span className="w-full h-0.5 bg-gradient-to-r from-white/0 via-white/50 to-white/0"></span>
            </>
          ))}

          <div className="flex justify-center">
            <button className="grid font-semibold text-xl leading-1 font-inter text-white/60 gap-1 content-center items-center justify-center w-[81px] h-[72px] bg-gradient-to-b from-white/10 to-white/5 text-center rounded-xl text-wrap">
              Soon
            </button>
          </div>

          <div className="w-36 font-inter bg-[#163AB9] px-4 py-3 shadow-2xl mx-auto rounded-2xl grid gap-2 justify-center items-center text-center">
            <p className="text-white text-xs font-semibold">Dont miss it !</p>
            <p className="text-white/60 text-[10px]">
              the airdrop of PDJT is coming soon
            </p>
            <button className="btn bg-[#DF0609] text-xs py-2.5 mt-2 text-white">
              Airdrop
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
