import solana from "assets/solana-sol-logo.png";
import CopyIcon from "assets/svgs/copy.svg";
import { useState } from "react";
import { copyClipBoard } from "utils";
import Trump from "assets/header/trump.png";

export default function ContractAddress() {
  const address = "E2o6gnnPa5FHW5kwLj7eNwPe77RtpBQn3nbX25fJ4Fyi";

  const [copied, setCopied] = useState(false);

  const copyAddress = () => {
    setCopied(true);
    copyClipBoard(address);

    setTimeout(() => {
      setCopied(false);
    }, 500);
  };

  return (
    <div className="rounded-2xl flex items-center py-2 sm:mt-6 mr-auto bg-white/30">
      <img
        src={Trump}
        className="w-20 sm:w-24 md:w-36 h-auto"
        width={281}
        height={261}
        alt="Trump"
      />
      <div className="flex flex-col">
        <div className="flex gap-2 items-center justify-center sm:justify-start">
          <p className="font-bold text-lg md:text-3xl">
            Contact Address:
          </p>
          <img
            src={solana}
            className="w-5 h-5 md:w-9 md:h-9"
            width={50}
            height={50}
            alt="solana"
          />
        </div>
        <div className="flex gap-2 items-center justify-center sm:justify-start max-w-[200px] sm:max-w-full">
          <p className="font-medium font-clash-display text-xs md:text-xl truncate">
            {address}
          </p>
          <button
            onClick={copyAddress}
            className={`transition-colors text-white ${
              copied ? "text-green-500" : "text-white"
            }`}
          >
            <img
              src={CopyIcon}
              className="w-5 h-5 md:h-9 md:w-9 text-white"
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
  );
}
