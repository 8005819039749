import phantom from "assets/wallets/phantom.png";
import trust from "assets/wallets/trust.png";
import solfare from "assets/wallets/solflare.png";
import ascvb from "assets/wallets/radium.png";
import exodus from "assets/wallets/exodus.png";
import safepal from "assets/wallets/safepal.png";
import assecvbdt from "assets/wallets/azbit.png";
import indoex from "assets/wallets/indoex.png";

import oppenheimer from "assets/teams/oppenheimer.png";
import john from "assets/teams/john-osborne.png";
import yoyo from "assets/teams/yoyo.png";
import donald from "assets/teams/mr-donald.png";

export const WALLETS = [
    {
        link: "https://phantom.app/en-GB/download",
        image: phantom
    },
    {
        link: "https://trustwallet.com/download",
        image: trust
    },
    {
        link: "https://gamma.solflare.com/download",
        image: solfare
    },
    {
        link: "https://raydium.io/swap/?outputMint=E2o6gnnPa5FHW5kwLj7eNwPe77RtpBQn3nbX25fJ4Fyi&inputMint=sol",
        image: ascvb
    },
    {
        link: "https://www.exodus.com/download/",
        image: exodus
    },
    {
        link: "https://www.safepal.com/en/download",
        image: safepal
    },
    {
        link: "https://azbit.com/exchange/PDJT_USDT",
        image: assecvbdt
    },
    {
        link: "https://www.indoex.io/trade/PDJT_USDT",
        image: indoex
    }
];

export const TEAMS = [
    {
        name: "Oppenheimer",
        image: oppenheimer,
        links: {
            discord: "",
            "x-white": "",
            "telegram-white": "https://t.me/TrumpOppenheimer"
        }
    },
    {
        name: "John Osborne",
        image: john,
        links: {
            discord: "",
            "x-white": "",
            "telegram-white": "https://t.me/JOHN_AMER1CA"
        }
    },
    {
        name: "Mr Donald",
        image: donald,
        links: {
            discord: "",
            "x-white": "",
            "telegram-white": "https://t.me/Mrdonald2025"
        }
    },
    {
        name: "YOYO",
        image: yoyo,
        links: {
            discord: "",
            "x-white": "",
            "telegram-white": "https://t.me/PDJTAnDrew"
        }
    }
];

export const WagmiProjectId = process.env.REACT_APP_WAGMI_PROJECT_ID || "";
export const WagmiProjectName = process.env.REACT_APP_WAGMI_PROJECT_NAME || "";