export function copyClipBoard(text: string) {
    if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
    } else {
        // text area method
        const textArea = document.createElement('textarea');
        textArea.value = text;
        // make the textarea out of viewport
        textArea.style.position = 'fixed';
        textArea.style.left = '-999999px';
        textArea.style.top = '-999999px';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        textArea.remove();
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatCompactNumber(number: number, setting: any) {
    const res = Intl.NumberFormat('en', setting).format(number);
    return res;
}