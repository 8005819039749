import solana from "assets/solana-sol-logo.png";
import trump from "assets/header/trump.png";
import SocialLinks from "../socialLinks/socialLinks";

export default function Footer() {
  return (
    <footer className="grid p-4 sm:p-6 relative mt-12 sm:mt-0 text-[ClashDisplay]">
      <span className="bg-gradient-to-b from-black/0 to-black absolute inset-0"></span>
      <div className="container relative mx-auto">
        <div className="flex justify-center">
          <div className="relative">
            <img
              src={solana}
              className="w-16 sm:w-24 object-contain -top-4 sm:-top-12 absolute -right-9"
              width={97}
              height={97}
              alt="solana"
            />
            <img
              src={trump}
              className="w-24 sm:w-36 object-contain drop-shadow-xl relative"
              width={145}
              height={145}
              alt="Trump"
            />
          </div>
        </div>
        <p className="text-center mt-4 font-[ClashDisplay] text-md sm:text-xl">
          contact@pdjtrump.org
        </p>
        <SocialLinks className="flex items-center justify-center gap-6 sm:gap-4 mt-6 flex-wrap" />
        <p className="text-[#96839B] text-center w-full max-w-[910px] font-[ClashDisplay] font-medium mx-auto leading-6 sm:leading-8 mt-6 text-md sm:text-xl">
          Please be advised that PDJT is a cryptocurrency created for
          entertainment purposes and is not affiliated with or endorsed by
          Donald J. Trump. While PDJT in may reference or incorporate elements
          related Trump’s persona, image, or likeness, it does not imply any
          direct endorsement, partnership, or approval by Trump. Any resemblance
          or association between PDJT and Donald J. Trump is purely coincidental
          and intended for satirical or humorous purposes.
        </p>
        <p className="text-center font-[ClashDisplay] text-md sm:text-xl mt-4">
          © 2024 PDJT. all Right Reserved
        </p>
      </div>
    </footer>
  );
}
